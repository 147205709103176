import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { ListItemButton, Typography, useTheme, Collapse } from "@mui/material";
import { Link } from "react-router-dom";

import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Tabs,
  Tab,
  Menu,
  Box,
  Divider,
  ListItemIcon,
} from "@mui/material";
import {
  MenuOutlined,
  ExpandMore,
  ExpandLess,
  HomeMaxRounded,
} from "@mui/icons-material";
// import { signInAnonymouslyUser } from "../firebase";
// import axios from "axios";
// import { CopyToClipboard } from "react-copy-to-clipboard";

function Header() {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [Header, setHeader] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  // const [referralLink, setReferralLink] = useState("");

  // const generateReferralLink = async () => {
  //   // Sign in anonymously and get anonymous ID
  //   const anonymousId = await signInAnonymouslyUser();

  //   // console.log(`anonymousId:${anonymousId}`);

  //   if (anonymousId) {
  //     try {
  //       // Make request to backend to generate referral link
  //       const response = await axios.post(
  //         "http://localhost:5000/refer/generate-referral-link",
  //         {
  //           anonymousId: anonymousId,
  //         }
  //       );

  //       // Set the generated referral link
  //       setReferralLink(response.data.referralLink);
  //     } catch (error) {
  //       console.error("Error generating referral link:", error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   generateReferralLink();
  // }, []);

  const changeBackground = () => {
    if (window.scrollY >= 50) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event) => {
    setIsMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
    setAnchorEl(null);
  };

  const handleToggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCollapse = () => {
    setIsCollapse(!isCollapse);
  };

  const links = [
    {
      text: "Home",
      route: "/",
      icons: <HomeMaxRounded sx={{ color: "#393E46" }} />,
    },
    {
      text: "Tools",
      route: "",
      subLinks: [
        {
          text: "VideoCompressor",
          route: "/videocompressor",
          icons: (
            <img
              src="../assets/logo1.svg"
              alt="Logo"
              style={{
                color: "white",
                height: "30px",
                width: "30px",
                objectFit: "cover",
              }}
            />
          ),
        },
        {
          text: "VoiceRecorder",
          route: "/voicerecorder",
          icons: (
            <img
              src="../assets/voice.svg"
              alt="Logo"
              style={{
                color: "white",
                height: "30px",
                width: "30px",
                // borderRadius:"5px",
                objectFit: "cover",
              }}
            />
          ),
        },
        // {
        //   text: "QRCodeCreator",
        //   route: "/qrcodecreator",
        //   icons: (
        //     <img
        //       src="../assets/qr.svg"
        //       alt="Logo"
        //       style={{
        //         color: "white",
        //         height: "30px",
        //         width: "30px",
        //         // borderRadius:"5px",
        //         objectFit: "cover",
        //       }}
        //     />
        //   ),
        // },
      ],
    },
    { text: "Price", route: "" },
    { text: "Sign Up", route: "" },
    { text: "Login", route: "" },
  ];

  return (
    <AppBar
      sx={{
        backgroundColor: Header ? "#ffffff" : "transparent",
        position: "fixed",
        top: "0",
        zIndex: "999999",
        boxShadow: Header ? "0px 2px 8px 1px rgba(0, 0, 0, 0.20)" : "none",
        transition: "0.3s ease,box-shadow 0.3s ease",
        padding: Header ? "0rem 3%" : "0.3rem 3%",
      }}
    >
      <Toolbar>
        <img
          src="../assets/icon.svg"
          alt="Logo"
          style={{
            color: "white",
            height: "37px",
            width: "37px",
            objectFit: "cover",
          }}
        />
        <Typography
          sx={{
            display: { xs: "none", md: "flex" },
            fontSize: "24px",
            fontWeight: 900,
            background:
              "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(187,75,75,1) 57%, rgba(252,176,69,1) 100%)",
            WebkitBackgroundClip: "text",
            color: "transparent",
          }}
          variant={theme.typography.h1}
        >
          TechTool
        </Typography>
        {isNonMobileScreens ? (
          <>
            <Tabs
              sx={{ marginLeft: "auto" }}
              indicatorColor="secondary"
              textColor="secondary"
              value={value}
              onChange={handleTabChange}
            >
              <Tab
                label="Home"
                value="0"
                sx={{ color: "#737373" }}
                component={Link}
                to="/"
                onClick={handleClose}
              />
              <Tab
                label="Tools"
                value="1"
                sx={{ color: "#737373" }}
                onClick={handleClick}
              />
              <Tab
                label="Price"
                value="2"
                sx={{ color: "#737373" }}
                component={Link}
                to=""
                onClick={handleClose}
              />
              <Tab
                label="Contact"
                value="3"
                sx={{ color: "#737373" }}
                component={Link}
                to=""
                onClick={handleClose}
              />
            </Tabs>
            <Button
              sx={{
                marginLeft: "auto",
                // background: "#727CF5",
                // "&:hover": { background: "#727CF5" },
              }}
              // variant="contained"
            >
              {/* Login */}
            </Button>
            <Button
              sx={{
                marginLeft: "10px",
                // background: "#727CF5",
                // "&:hover": { background: "#727CF5" },
              }}
              // variant="contained"
            >
              {/* SignUp */}
            </Button>
            {/* <CopyToClipboard text={referralLink}>
              <Button
                sx={{
                  marginLeft: "10px",
                  background: "#727CF5",
                }}
                variant="contained"
              >
                <Typography sx={{ color: "white" }}>Invite Friends</Typography>
              </Button>
            </CopyToClipboard> */}

            {/* <Button
              sx={{
                marginLeft: "10px",
                background: "#727CF5",
              }}
              variant="contained"
            >
              <Typography sx={{ color: "white" }}>Credits:0</Typography>
            </Button> */}
          </>
        ) : (
          <IconButton
            color="black"
            onClick={handleToggleMobileMenu}
            sx={{ marginLeft: "auto" }}
          >
            <MenuOutlined />
            {/* {isMobileMenuOpen ? <CancelOutlinedIcon sx={{fontSize:"30px",color:"#AF0404"}} /> : <MenuOutlined />} */}
          </IconButton>
        )}
        <Drawer
          anchor="right"
          open={isMobileMenuOpen}
          onClose={handleToggleMobileMenu}
          // sx={{ zIndex: "999999"}}
          sx={{
            width: 250,
            "& .MuiDrawer-paper": {
              color: "",
              backgroundColor: "",
              boxSixing: "border-box",
              borderWidth: isNonMobileScreens ? 0 : "2px",
              width: 250,
            },

            zIndex: "999999",
          }}
        >
          <Box
            padding={"0.6rem 1rem 0rem 1rem"}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <img
              src="../assets/icon.svg"
              alt="Logo"
              style={{
                color: "white",
                height: "37px",
                width: "37px",
                objectFit: "cover",
              }}
            />
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 900,
                background:
                  "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(187,75,75,1) 57%, rgba(252,176,69,1) 100%)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
              variant="h1"
            >
              TechTool
            </Typography>
            <Box></Box>
          </Box>
          <List sx={{ padding: "0.3rem 0.5rem" }}>
            {links.map(({ text, route, icons, subLinks }, index) => (
              <>
                <ListItem
                  key={index}
                  component={Link}
                  to={route}
                  disablePadding
                  onClick={index === 1 ? handleCollapse : () => {}}
                  sx={{
                    "&:hover": {
                      background: "#7868E6",
                      "& .css-1wyq04w-MuiSvgIcon-root": {
                        color: "white",
                      },
                    },

                    borderRadius: "10px",
                  }}
                >
                  <ListItemButton>
                    {/* <ListItemIcon>{icons}</ListItemIcon> */}
                    <ListItemText
                      primary={text}
                      sx={{
                        color: "#393E46",
                        "&:hover": {
                          color: "#EEEEEE",
                        },
                      }}
                    />
                    {index === 1 && (
                      <>
                        {isCollapse ? (
                          <ExpandLess
                            sx={{
                              color: "#393E46",
                              "&:hover": {
                                color: "#EEEEEE",
                              },
                            }}
                          />
                        ) : (
                          <ExpandMore
                            sx={{
                              color: "#393E46",
                              "&:hover": {
                                color: "#EEEEEE",
                              },
                            }}
                          />
                        )}
                      </>
                    )}
                  </ListItemButton>
                </ListItem>

                {index === 1 && subLinks && (
                  <Collapse in={isCollapse} timeout="auto" unmountOnExit>
                    {subLinks.map((subLink, subIndex) => (
                      <Box>
                        <ListItem key={subIndex} disablePadding>
                          <ListItemButton component={Link} to={subLink.route}>
                            <ListItemIcon>{subLink.icons}</ListItemIcon>
                            <ListItemText
                              primary={subLink.text}
                              sx={{
                                mr: "200px",
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      </Box>
                    ))}
                  </Collapse>
                )}
              </>
            ))}
          </List>
        </Drawer>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleClose}
          sx={{ ml: -6, zIndex: "9999999" }}
        >
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "5px",
              boxShadow: " 0px 0px 12px #00000026",
              px: 0.5,
            }}
          >
            <Box p={1}>
              <Link
                style={{ color: "black", textDecoration: "none" }}
                to="/videocompressor"
                onClick={() => setValue(1)}
                onClose={handleClose}
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  gap="12px"
                  padding={1}
                >
                  <img
                    src="../assets/logo1.svg"
                    alt="Logo"
                    style={{
                      color: "white",
                      height: "40px",
                      width: "40px",
                      objectFit: "cover",
                    }}
                  />
                  <Typography
                    color="black"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      wordWrap: "break-word",
                      width: 100,
                    }}
                    variant={theme.typography.fontFamily}
                  >
                    Video Compressor
                  </Typography>
                </Box>
              </Link>
              <Divider />
              {/* Other Menu items */}
              <Link
                style={{ color: "black", textDecoration: "none" }}
                to="/VoiceRecorder"
                onClick={() => setValue(1)}
                onClose={handleClose}
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  gap="12px"
                  padding={1}
                >
                  <img
                    src="../assets/voice.svg"
                    alt="Logo"
                    style={{
                      color: "white",
                      height: "40px",
                      width: "40px",
                      // borderRadius:"5px",
                      objectFit: "cover",
                    }}
                  />
                  <Typography
                    color="black"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      wordWrap: "break-word",
                      width: 100,
                    }}
                    variant={theme.typography.fontFamily}
                  >
                    Voice Recorder
                  </Typography>
                </Box>
              </Link> 
              {/* <Divider />
              {/* <Link
                style={{ color: "black", textDecoration: "none" }}
                to="/videocompressor"
                onClose={handleClose}
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  gap="12px"
                  padding={1}
                >
                  <img
                    src="../assets/qr.svg"
                    alt="Logo"
                    style={{
                      color: "white",
                      height: "40px",
                      width: "40px",
                      // borderRadius:"5px",
                      objectFit: "cover",
                    }}
                  />
                  <Typography
                    color="black"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      wordWrap: "break-word",
                      width: 100,
                    }}
                  >
                    QR Code Creator
                  </Typography>
                </Box>
              </Link> */}
            </Box>
          </Box>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
