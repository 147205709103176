import React from "react";
import { Box, Typography, Link } from "@mui/material";
import FlexCenter from "../StyledComponents/FlexCenter";

function PromotionalComponent() {
  return (
    <FlexCenter>
      <Box
        sx={{
          width: { xs: "80%", sm: "80%", md: "75%", lg: "75%" },
          background: "white",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
          borderRadius: "15px",
          flexWrap: "nowrap",
          display: { xs: "block", sm: "block", md: "flex", lg: "flex" },
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "40%", lg: "40%" },
            // border: "1px solid black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Box
            sx={{
              backgroundImage: `url(../assets/new-phone-2.png)`, // Set the background image
              backgroundSize: "cover", // Ensure the image covers the entire box
              backgroundPosition: "center", // Center the image
              backgroundRepeat: "no-repeat", // Do not repeat the image
              width: { xs: "260px", sm: "420px", md: "420px", lg: "420px" },
              height: { xs: "380px", sm: "410px", md: "380px", lg: "380px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              // filter: "drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.6))",
              boxSizing: "border-box",
              border:"1px solid red",
              zIndex:999999
            }}
          >
            {" "}
            <img
              src="../assets/vc-Home-screen-1.png"
              alt="Video-Compressor-Screen"
              style={{
                // maxHeight: {xs:"100%",sm:"",md:"93%",lg:"93%"},
                // maxWidth: {xs:"100%",sm:"",md:"95%",lg:"95%"},
                maxHeight: "70%",
                maxWidth: "75%",
                objectFit: "cover",
                // borderRadius: "1rem",
                zIndex:1,
                clipPath:"fill-box"
              }}
            />{" "}
          </Box> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              filter: "drop-shadow(1px 8px 16px rgba(0, 0, 0, 0.6))",
            }}
          >
            <img
              src="../assets/iPhone  Screen.svg"
              alt="Video-Compressor-Screen"
              style={{
                // maxHeight: {xs:"100%",sm:"",md:"93%",lg:"93%"},
                // maxWidth: {xs:"100%",sm:"",md:"95%",lg:"95%"},
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "cover",
                // borderRadius: "1rem",
                zIndex: 1,
                clipPath: "fill-box",
              }}
            />{" "}
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "60%", lg: "60%" },
            // border: "1px solid black",
          }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "block", md: "flex", lg: "flex" },
              gap: "10px",
              // border:"1px solid red"
              mt:{xs:"1rem",sm:"1rem",md:"0rem",lg:"0rem"}
            }}
          >
            <Box sx={{ justifyContent: "center", display: "flex" }}>
              <img
                src="../assets/vc-logo-final.png"
                alt="video-compressor-logo"
                style={{
                  color: "white",
                  height: "80px",
                  width: "80px",
                  objectFit: "cover",
                  borderRadius: "1rem",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                }}
              />
            </Box>
            <Box
              sx={{
                // position: "relative",
                width: "100%",
                //  border:"1px solid red",
                display: "block",
                margin: "1rem auto 0rem auto",
              }}
            >
              <Typography
                sx={{
                  color: "#FF585C",
                  fontSize: "1.25rem",
                  fontWeight: "800",
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "start",
                    lg: "start",
                  },
                  // position: "absolute",
                  // top: { xs: "1.5rem", sm: "1.5rem", md: "50%", lg: "50%" },
                  // left: { xs: "50%", sm: "50%", md: "24%", lg: "20%" },
                  // transform: "translate(-50%, -50%)",
                  fontFamily: "inherit",
                }}
              >
                Video Compressor
              </Typography>
              <Typography
                sx={{
                  // color: "#FF585C",
                  // fontWeight: "00",
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "start",
                    lg: "start",
                  },
                  // position: "absolute",
                  // top: { xs: "2.5rem", sm: "2.5rem", md: "70%", lg: "70%" },
                  // left: { xs: "50%", sm: "50%", md: "8%", lg: "8%" },
                  // transform: "translate(-50%, -50%)",
                  fontFamily: "inherit",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                Free
              </Typography>
            </Box>
          </Box>

          {/* <Box
            sx={{
              mt: { xs: "4rem", sm: "4rem", md: "0.75rem", lg: "0.75rem" },
            }}
          >
            <Typography
              sx={{
                color: "#00000080",
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "inherit",
                padding: {
                  xs: "0rem 1rem",
                  sm: "0rem 1rem",
                  md: "0rem 0rem",
                  lg: "0rem 0rem",
                },
              }}
            >
              Downsize Video is an all-in-one app for compressing, editing, and
              sharing videos effortlessly. Quickly reduce video size in three
              simple steps: select videos, choose quality, and compress. Watch,
              save, or share videos directly on social media or via email.
            </Typography>
          </Box>

          <Box
            gap={2}
            display={{ xs: "block", md: "flex" }}
            justifyContent={{
              xs: "center",
              sm: "center",
              md: "flex-start",
              lg: "flex-start",
            }}
            alignItems={"center"}
            mt={"1rem"}
          >
            <Link href="https://apps.apple.com/us/app/video-compressor-file-resizer/id6475106894">
              <Box
                bgcolor="black"
                minWidth="80px"
                minHeight="20px"
                maxWidth="100px"
                p="0.5rem 1rem"
                borderRadius="4px"
                boxShadow="0px 2px 8px 1px rgba(0, 0, 0, 0.50)"
                m={{ xs: "10px auto", sm: "10px auto", md: "0px", lg: "0px" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src="../assets/apple.svg"
                  alt=""
                  style={{
                    //   color: "black",
                    height: "20px",
                    width: "80px",
                    objectFit: "fill",
                  }}
                />
              </Box>
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=com.raytechnoto.video.compressor.converter&hl=en&g%20l=us"
              sx={{ textDecoration: "none" }}
            >
              <Box
                bgcolor="black"
                minWidth="80px"
                minHeight="20px"
                maxWidth="100px"
                p="0.5rem 1rem"
                borderRadius="4px"
                boxShadow="0px 2px 8px 1px rgba(0, 0, 0, 0.50)"
                m={{ xs: "10px auto", sm: "10px auto", md: "0px", lg: "0px" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src="../assets/Google-play.svg"
                  alt=""
                  style={{
                    // color: "black",
                    height: "20px",
                    width: "80px",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Link>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column-reverse",
                lg: "column-reverse",
              },
              alignItems: {
                xs: "center",
                sm: "center",
                md: "flex-start",
                lg: "flex-start",
              },
              gap: "1rem", // Add spacing between the elements
            }}
          >
            {/* Links Section */}
            <Box
              gap={2}
              display={{ xs: "block", md: "flex" }}
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "flex-start",
                lg: "flex-start",
              }}
              alignItems={"center"}
              mt={"1rem"}
            >
              <Link href="https://apps.apple.com/us/app/video-compressor-file-resizer/id6475106894">
                <Box
                  bgcolor="black"
                  minWidth="120px"
                  minHeight="20px"
                  maxWidth="100px"
                  p="0.5rem 2rem"
                  borderRadius="4px"
                  boxShadow="0px 2px 8px 1px rgba(0, 0, 0, 0.50)"
                  m={{ xs: "10px auto", sm: "10px auto", md: "0px", lg: "0px" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    src="../assets/apple.svg"
                    alt=""
                    style={{
                      height: "30px",
                      width: "90px",
                      objectFit: "fill",
                    }}
                  />
                </Box>
              </Link>
              <Link
                href="https://play.google.com/store/apps/details?id=com.raytechnoto.video.compressor.converter&hl=en&g%20l=us"
                sx={{ textDecoration: "none" }}
              >
                <Box
                  bgcolor="black"
                  minWidth="120px"
                  minHeight="20px"
                  maxWidth="100px"
                  p="0.5rem 2rem"
                  borderRadius="4px"
                  boxShadow="0px 2px 8px 1px rgba(0, 0, 0, 0.50)"
                  m={{ xs: "10px auto", sm: "10px auto", md: "0px", lg: "0px" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    src="../assets/Google-play.svg"
                    alt=""
                    style={{
                      height: "30px",
                      width: "90px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Link>
            </Box>

            {/* Typography Section */}
            <Box
              sx={{
                mt: { xs: "0rem", sm: "0rem", md: "0.75rem", lg: "0.75rem" },
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "left",
                  lg: "left",
                }, // Center text for small screens
              }}
            >
              <Typography
                sx={{
                  color: "#00000080",
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "inherit",
                  padding: {
                    xs: "0rem 1rem",
                    sm: "0rem 1rem",
                    md: "0rem 0rem",
                    lg: "0rem 0rem",
                  },
                }}
              >
                Downsize Video is an all-in-one app for compressing, editing,
                and sharing videos effortlessly. Quickly reduce video size in
                three simple steps: select videos, choose quality, and compress.
                Watch, save, or share videos directly on social media or via
                email.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </FlexCenter>
  );
}

export default PromotionalComponent;
