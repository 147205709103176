import React from "react";
import { Box, Typography, useMediaQuery,Link } from "@mui/material";
// import FlexBetween from "../StyledComponents/FlexBetween";
// import FlexCenter from "../StyledComponents/FlexCenter";

function PromoteComponents() {
  const isNonMobileScreen = useMediaQuery("(min-width:900px)");
  return (
    <Box padding={isNonMobileScreen ? "0rem 3%" : "0rem 0%"}>
      {isNonMobileScreen && (
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box>
            <img
              src="../assets/cardiobot-icon.jpg"
              alt="cardiobot-icon"
              style={{
                color: "white",
                height: "80px",
                width: "80px",
                objectFit: "cover",
                borderRadius: "1rem",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
              }}
            />
          </Box>
          <Box sx={{ position: "relative", width: "100%" }}>
            <Typography
              sx={{
                color: "#FF585C",
                fontSize: "16px",
                fontWeight: "800",
                position: "absolute",
                top: "1.5rem",
                fontFamily: "inherit",
              }}
            >
              CardioBot
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 1 }}>
        <Typography
          sx={{
            color: "#00000080",
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "inherit",
          }}
        >
          {/* The file "filename" (116 MB) exceeds the 50 MB limit, and we cannot
          compress it. Consider using the Compress Video: Downsize Video app, an
          all-in-one video compressor for downsizing files quickly and easily.
          Select your videos, choose the quality, and compress them in just
          three steps! Perfect for sharing videos on social media or sending via
          email, this app also lets you watch, save, and compare your compressed
          videos effortlessly. Download Compress Video: Downsize Video now for
          fast and reliable video compression. */}
          The file "filename" (116 MB) exceeds the 50 MB limit, so we cannot
          compress it. Try the Compress Video: Downsize Video app to reduce file
          sizes quickly. Select videos, choose quality, and compress in just
          three steps! This app is perfect for sharing on social media or
          sending via email. Download now for fast, easy video compression.
        </Typography>

        <Box padding="1rem  0px">
        {/* <FlexCenter> */}
          <Box gap={2} display={{ xs: "block", md: "flex",}} justifyContent={"flex-start"} alignItems={"center"}>
            <Link href="https://apps.apple.com/us/app/video-compressor-file-resizer/id6475106894">
              <Box
                bgcolor="black"
                minWidth="80px"
                minHeight="20px"
                p="0.5rem 1rem"
                borderRadius="4px"
                boxShadow="0px 2px 8px 1px rgba(0, 0, 0, 0.50)"
                mt={isNonMobileScreen ? "0px" : "10px"}
              >
                <img
                  src="../assets/apple.svg"
                  alt=""
                  style={{
                    //   color: "black",
                    height: "20px",
                    width: "80px",
                    objectFit: "fill",
                  }}
                />
              </Box>
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=com.raytechnoto.video.compressor.converter&hl=en&g%20l=us"
              sx={{ textDecoration: "none" }}
            >
              <Box
                bgcolor="black"
                minWidth="80px"
                minHeight="20px"
                p="0.5rem 1rem"
                borderRadius="4px"
                boxShadow="0px 2px 8px 1px rgba(0, 0, 0, 0.50)"
                mt={isNonMobileScreen ? "0px" : "10px"}
              >
                <img
                  src="../assets/google.svg"
                  alt=""
                  style={{
                    //   color: "black",
                    height: "20px",
                    width: "80px",
                    objectFit: "fill",
                  }}
                />
              </Box>
            </Link>
          </Box>
        {/* </FlexCenter> */}
      </Box>
      </Box>
    </Box>
  );
}

export default PromoteComponents;
