// import { initializeApp } from "firebase/app";
// // import { getAnalytics } from "firebase/analytics";
// import { getMessaging } from "firebase/messaging";

// const firebaseConfig = {
//     apiKey: "AIzaSyCKjfimB3TunoHK4QbC36geRSw0kPm-Z74",
//     authDomain: "techtoolplus-6c066.firebaseapp.com",
//     projectId: "techtoolplus-6c066",
//     storageBucket: "techtoolplus-6c066.appspot.com",
//     messagingSenderId: "891859229700",
//     appId: "1:891859229700:web:cb29681b17c8d06cd22951"
//   };

// export const app = initializeApp(firebaseConfig);
// export const messaging = getMessaging(app);
// export const analytics = getAnalytics(app);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
import { getAuth, signInAnonymously } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_Id
};


// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const analytics = getAnalytics(app);
const auth = getAuth(app);

export const signInAnonymouslyUser = async () => {
  try {
    const userCredential = await signInAnonymously(auth);
    return userCredential.user.uid; // Firebase Anonymous ID (unique per user)
  } catch (error) {
    console.error("Error signing in anonymously:", error);
    return null;
  }
};


